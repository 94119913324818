.DataViewPanel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CData {
  width: 100%;
  height: 100%;
}