@import "src/common/common";
.ButtonPanel {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 400 * $uh;
  left: (100vw - 1600 * $uw) / 2;
  z-index: 2;
  width: 1600 * $uw;
  justify-content: space-around;

  .ButtonItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .Img {
      width: 203 * $uw;
      height: 203 * $uw;
    }
    .Title {
      white-space: nowrap;
      color: rgb(0,222,255);
    }
    :active {
    }
  }

}