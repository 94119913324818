@import "src/common/common";


.AData {
  width: 1000 * $uw;
  height: 1000 * $uw;
  position: relative;
  .AView {
    width: 100%;
    height: 100%;
  }
  .ADNumber {
    position: absolute;
    left: 390 * $uw;
    top: 400 * $uw;
    font-size: 100 * $uw;
    font-weight: 1000;
    color: white;
  }
  .ADTitle {
    position: absolute;
    font-size: 70*$uw;
    top: 550 * $uw;
    left: 400 * $uw;
    color: white;
  }
}
