@import "src/common/common";

.Data {
  width: ((490 * 3) + 6 * 3) * $uw ;
  height: 100%;
  margin-top: 40 * $uh;
  overflow: hidden;
}

.DataContent {
  height: 1200 * $uh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.DataContent::-webkit-scrollbar{
  display: none;
}

.ButtonLabelLine {
  margin-top: 110 * $uh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .ButtonLine {
    border-color: rgb(14,43,109);
    border-width: 2px;
    border-style: solid;
    height: 120 * $uw;
    width: 490 * $uw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(9,222,255)
  }
  .ButtonLineActive {
    border-color: rgb(14,43,109);
    background: rgb(14,43,109);
    border-width: 2px;
    border-style: solid;
    height: 120 * $uw;
    width: 490 * $uw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(9,222,255)
  }
}

.DataLine {
  width: 100%;
  height: 140 * $uh;
  display: flex;
  flex-direction: row;
  flex: 1;
  .Key {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: 40 * $uw;
    color: rgb(140,210,255);
  }
  .Value {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    color: rgb(140,210,255);
  }
}