.DataViewPanel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
.BData {
  width: 90%;
  height: 100%;
}