@import "src/common/common";

.DataPanelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1680 * $uw;
  height: 1950 * $uh;
  position: relative;
  .BKImg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}