@import "src/common/common";
.Home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .Back {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 400 * $uh;
    left: 75vw;
    z-index: 2;
    width: 180 * $uw;
    justify-content: space-around;
    color: white;
    background: black;
    align-items: center;
    height: 200 * $uh;
    cursor: pointer;
  }
  .Header {
    width: 100vw;
    height: 330 * $uh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 160 * $uw;
    .HL {
      width: 40vw;
    }
    .HCB {
      width: 30vw;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      .BImg {
        position: absolute;
        width: 30vw;
      }
      .Text {
        color: rgb(92,221,252);
        z-index: 2;
      }
    }
    .HR {
      width: 40vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .Time {
        margin-right: 500 * $uw;
        color: rgb(92,221,252);
        font-size: 14px;
      }
    }
  }
  .Content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .Left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 2000 * $uw;
      height: 100%;
      .A1 {
        margin-top: 10 * $uh;
      }
      .A2 {
        margin-top: 50 * $uh;
      }
    }
    .Map {
      padding: 16px 16px 16px 16px;
      border-radius: 16px;
      width: 80%;
      height: 60%;
    }
    .Right {
      width: 2000 * $uw;
      height: 100%;
      .A3 {
        margin-top: 10 * $uh;
      }
      .A4 {
        margin-top: 50 * $uh;
      }
    }
  }
}

.PanelTitle {
  color: rgb(50,180,217);
  font-size: 78 * $uw;
  margin-top: 40 * $uh;
  z-index: 10;
  font-weight: 700;
}

.ADataPanel {
  margin-top: 128 * $uh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.NumberPanel {
  width: 740 * $uw;
  height: 380 * $uh;
  border-radius: 2px;
  background: rgba(5,21,66,0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ADataViewPanel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NumberTitle {
  font-size: 100 * $uw;
  font-weight: 700;
  margin-top: 40 * $uh;
  color: rgb(145,210,255);
}

.Number {
  margin-top: 10 * $uh;
  font-size: 90 * $uw;
  font-weight: 700;
}

.NumberSize {
  font-size: 72 * $uw;
  font-weight: 700;
}
